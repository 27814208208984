import React from "react";

const Construction = () => {
  return (
    <>
      <div className="construction-page">
        <h1>Site en construction</h1>
      </div>
    </>
  );
};

export default Construction;
